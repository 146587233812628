/* Datepicker CSS */
.react-datepicker__header {
  background-color: transparent !important;
  border: 0 !important;
}

.date-picker-selects select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.react-datepicker__children-container {
  width: auto !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: #009AF1 !important;
  color: #FFFFFF !important;
  font-weight: 600;
}

.react-datepicker__day--disabled {
  background-color: #FFFFFF;
  color: #AFB8C7 !important;
}

.date_selected {
  background-color: #009AF1 !important;
  color: #FFFFFF !important;
  font-weight: 600;
}